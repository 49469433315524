import React from "react"
import { useState } from "react"
import Button from "components/ui/button"
import PhoneIcon from "components/icons/phoneIcon"
import LocationIcon from "components/icons/locationIcon"
import Layout from "components/layout"
import SEO from "components/seo"

const ContactPage = () => {
  const phoneNumber = () => {
    return (
      <div className="d-flex">
        <PhoneIcon />
        <a
          href="tel:+918754444356"
          className="ml-20 font-size-14 text-secondary m-0"
        >
          +91 8754444356
        </a>
      </div>
    )
  }
  const [showForm, setShowForm] = useState(true)
  return (
    <Layout path="/contact">
      <SEO
        title="Contact"
        description="Contact Page Description"
        keywords={[
          `Rishaba`,
          `Butterfly`,
          `Home Appliances`,
          `Industrial Design`,
          `Engineering`,
          `Mold Design`,
          `Manufacturing`,
        ]}
        ogUrl="/contact"
      />
      <div className="center-between align-items-center col-reverse-sm w-100 flex-wrap container-lg mb-150-lg mt-80-lg">
        <div className="w-50-lg w-100-sm container-sm">
          <h1 className="font-size-66-lg font-bold mb-30 m-0">Contact us</h1>
          {showForm && (
            <form method="post" action="#" onSubmit={() => setShowForm(false)}>
              <input
                type="text"
                name="name"
                id="name"
                placeholder="Name"
                className="w-100 my-10 h-40-px"
              />
              <div className="d-flex justify-content-between flex-wrap">
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email"
                  className="w-40-lg w-100-sm my-10 h-40-px"
                />
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  placeholder="Phone Number"
                  className="w-40-lg w-100-sm my-10 h-40-px"
                />
              </div>
              <input
                type="text"
                name="country"
                id="country"
                placeholder="Country"
                className="w-100 my-10 h-40-px"
              />
              <textarea
                name="message"
                id="message"
                rows="5"
                placeholder="Message"
                className="w-100 my-10"
              />
              <div className="d-flex justify-content-between align-items-center">
                <Button type="submit" name="Submit" />
                {phoneNumber()}
              </div>
            </form>
          )}
          {!showForm && (
            <div
              className="d-flex column justify-content-between"
              style={{ height: "50vh" }}
            >
              <div>
                <h3 className="font-bold">Thank you!</h3>
                <p className="text-secondary">
                  Your message has been successfully sent. <br /> We will
                  contact you very soon!
                </p>
              </div>
              {phoneNumber()}
            </div>
          )}
        </div>
        <div className="w-40-lg w-100-sm ml-20-lg mb-20 position-relative">
          <div
            className="position-absolute d-flex w-50 justify-content-around"
            style={{ background: "white", left: 10, top: 10, padding: 10 }}
          >
            <LocationIcon />
            <p className="text-secondary">
              Rishaba pvt. ltd , building no - 201 , <br /> Eggatur , Chennai
            </p>
          </div>
          <iframe
            title="maps"
            className="w-100"
            height="450"
            id="gmap_canvas"
            src="https://snazzymaps.com/embed/264052"
            frameBorder="0"
            scrolling="no"
            marginHeight="0"
            marginWidth="0"
          ></iframe>
        </div>
      </div>
    </Layout>
  )
}

export default ContactPage
